.css-12dxq6v-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #ef798a !important;
}
.css-1aquho2-MuiTabs-indicator {
    background-color: #ef798a !important;
}
.css-gz79ye-MuiButtonBase-root-MuiButton-root {
    color: #ef798a !important;
}
.rp-default-input-section input {
    display:none;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #ef798a !important;
}
.css-19kzrtu {
    padding: 0px !important;
    height: 690px !important;
    overflow-y: scroll;
}
.css-dv0mq-MuiTableCell-root {
    padding: 1px !important;
}
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px !important;
    border: transparent !important;
} */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 2px !important;
}