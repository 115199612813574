/* Overall wrapper */
.chatFile-wrapper {
  width: 100%;
  height: 100vh; /* Adjusted to fit the viewport height */
  display: flex;
  flex-direction: column; /* To make sure the header stays on top */
  margin-top: 15px;
}

/* Content section */
.chatFile-content {
  flex: 1; /* This will make the content section take up remaining space */
  display: flex;
}

.chatFile-icon{
  color: #ef798a;
  cursor: pointer;
}

/* Left half styles */
.chatFile-left-half {
  flex: 1; /* Adjusted to take up 50% of the content section */
  overflow-y: hidden;
  width: 80%;
}

/* Insert icons container */
.chatFile-insert {
  border-bottom: 1px solid #000;
  background-color: #fff;
  height: 50px; /* Adjusted to fit the content */
  color: #000;
  display: flex;
  padding-left: 20px;
  align-items: center;
  overflow-x: auto; /* Horizontal scroll if the icons overflow */
}

.chatFile-insert label {
  margin-right: 20px;
  cursor: pointer;
  align-items: center;
  padding-bottom: 2px;
}

.chatFile-insert label i {
  font-style: normal;
}

/* Right half styles */
.chatFile-right-half {
  width: 20%; /* Adjusted to take up 20% of the content section */
  background-color: #fff;
  border-left: 1px solid #000;
  overflow-y: auto; /* Vertical scroll if the uploaded files overflow */
  color: #000;
}

.chatFile-right-half ul {
  list-style: none;
  padding: 0;
  margin: 0%;
  background-color: #f5f5f5;
}

.chatFile-right-half ul li {
  color: #000;
  padding: 8px 10px;
  border-bottom: 1px solid #444;
}

.chatFile-right-half ul li:last-child {
  border-bottom: none;
}

/* Checkbox styles */
.chatFile-right-half ul li {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Add spacing between items */
}

.chatFile-right-half ul li input[type="checkbox"] {
  margin-right: 10px; /* Add spacing between checkbox and label */
}

.chatFile-right-half ul li label {
  color: #000;
}


/* Default style for the list items */
li {
  margin-bottom: 5px;
}


/* Style for the checkbox label */
.chatFile-right-half ul li input[type="checkbox"] + label {
  display: inline-block;
  cursor: pointer;
}

/* Style for the filename */
.chatFile-filename {
  display: inline-block;
  padding-left: 5px;
}

/* Style for the checked checkbox */
.chatFile-right-half ul li input[type="checkbox"]:checked + label:before {
  content: "\2713"; /* White checkmark */
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  background: linear-gradient(to right, #ef798a, #674f87);
  color: white;
  border-radius: 50%;
  margin-right: 5px;
}
/* Hide the list item when the checkbox is checked */
.chatFile-right-half ul li input[type="checkbox"]:checked{
  display: none;
}


/* Chat app styles */
.chatFile-chatapp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjusted to fit the content */
  background-color: #fff;
}

/* Chat container styles */
.chatFile-chat-container {
  width: 80%; /* Adjusted to fit the content */
  height: 80%; /* Adjusted to fit the content */
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.chatFile-3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #000;
  margin: auto;
  margin-top: 2%;
}

/* Chat header styles */
.chatFile-chat-header {
  background: linear-gradient(to right, #ef798a, #674f87);
  padding: 15px;
  font-weight: bold;
  display: flex;
  color: white;
  font-weight: bold;
}

/* Chat body styles */
.chatFile-chat-body {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Response text styles */
.chatFile-response-text {
  margin: 0;
}

/* Chat input styles */
.chatFile-chat-input {
  padding: 10px;
  background-color: #f5f5f5;
  display: flex;
}

.chatFile-chat-input input {
  width: 95%;
  height: 2em;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
}
.chatFile-chat-input button{
  width: 40px;
  border: none;
  cursor: pointer;
  background-color: #f5f5f5;
}
.chatFile-chat-input button .chatFile-icon{
 color: #674f87;
}
.chatFile-btnrcrd{
  color: #674f87;
}

/* Chat body styles */
.chatFile-chat-body {
flex-grow: 1;
padding: 20px;
overflow-y: auto;
display: flex;
flex-direction: column;
}

/* Audio message styles */
.chatFile-audio-message {
align-self: flex-end; /* Right-align the audio message */
margin-bottom: 10px; 
background-color: #674f87;
padding: 5PX;
}

.chatFile-audio-message audio {
width: 350px; /* Adjust the width of the audio player */
}

/* Reply message styles */
.chatFile-reply-message {
align-self: flex-start; /* Left-align the reply message */
margin-bottom: 10px;
background-color: #ef798a;
}

.chatFile-header {
  display: flex;
  padding: 10px;
  background: linear-gradient(to right, #ef798a, #674f87);
  color: white;
  font-weight: bold;
  height: 12%;
}

.chatFile-avatar img {
  position: absolute;
  top: 5px;
  width: 80px;
  height: 80px;
  margin-right: 10px;
  background: transparent;
}

.chatFile-avatar img:hover{
  cursor: pointer;
  width: 85px;
  height: 85px;
}
.chatFile-bot-name{
  max-width: 30%;
  margin: auto;
}
.chatFile-messages {
  flex: 1;
  padding: 10px 0px;
  overflow-y: auto;
  background-color: #f1f0f0;
}

.chatFile-message {
  margin-bottom: 10px;
  width: fit-content;
  max-width: 70%;
  padding: 8px 12px;
}

.chatFile-message-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.chatFile-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  /* border: 1px solid #674f87; */
  background-color: #fff;
}

.chatFile-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.chatFile-user {
  /* justify-content: flex-end; */
  flex-direction: row-reverse;
}

.chatFile-bot {
  /* justify-content: flex-start; */
  flex-direction: row;
}

.chatFile-user .chatFile-message {
  text-align: right;
  background-color: #354759;
  color: white;
  align-self: flex-end;
  border-radius: 15px;
  margin-left: 10px;
}

.chatFile-bot .chatFile-message {
  text-align: left;
  background-color: #fff;
  align-self: flex-start;
  border-radius: 15px;
}


.chatFile-input-container {
  display: flex;
  padding: 10px;
  background-color: #f1f0f0; 
  border-top: 4px solid #fff;
}

.chatFile-input-container input {
  flex: 1;
  padding: 13px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.chatFile-input-container button{
  color: #674f87;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 2px;
}

.chatFile-input-container button:hover{
  color: #ef798a;
}

.chatFile-btnUpload{
  padding: 6px 8px;
  color: #674f87;
  border: none;
  border-radius: 50%;
}
.chatFile-btnUpload:hover{
  color: #ef798a;
}
.chatFile-chat-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.chatFile-5);
}

.chatFile-close-button {
  position: absolute;
  top: 20px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 25px;
  cursor: pointer;
  
}

/* Rest of the CSS remains the same */

.chatFile-chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: none;
  display: flex;
  cursor: pointer;
  
}

.chatFile-chat-icon img {
  width: 70px;
  height: 70px;
}

/* Hide the Scrollbar */
.chatFile-messages::-webkit-scrollbar {
  display: none;
}

/* Optional: Define Styles for Scrollbar Track and Thumb */
.chatFile-messages::-webkit-scrollbar-track {
  background-color: transparent; /* Change as needed */
}

.chatFile-messages::-webkit-scrollbar-thumb {
  background-color: transparent; /* Change as needed */
}
