/* App.css */
.app {
  position: relative;
}

.chat-modal {
  position: absolute;
  right: 5%;
  bottom: 2%;
  width: 450px;
  height: 550px;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 550px;
    width: 450px;
    border-radius: 20px;
    overflow: hidden;
    font-family: Arial, sans-serif;
    
  }
  
  .header {
    display: flex;
    padding: 10px;
    background: linear-gradient(to right, #ef798a, #674f87);
    color: white;
    font-weight: bold;
    height: 12%;
  }
  
  .avatar img {
    position: absolute;
    top: 5px;
    width: 80px;
    height: 80px;
    margin-right: 10px;
    background: transparent;
  }

  .avatar img:hover{
    cursor: pointer;
    width: 85px;
    height: 85px;
  }
  .bot-name{
    max-width: 30%;
    margin: auto;
  }
  .messages {
    flex: 1;
    padding: 10px 0px;
    overflow-y: auto;
    background-color: #f1f0f0;
  }
  
  .message {
    margin-bottom: 10px;
    width: fit-content;
    max-width: 70%;
    padding: 8px 12px;
  }

  .message-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    /* border: 1px solid #674f87; */
    background-color: #fff;
  }
  
  .icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  .user {
    /* justify-content: flex-end; */
    flex-direction: row-reverse;
  }

  .bot {
    /* justify-content: flex-start; */
    flex-direction: row;
  }
  
  .user .message {
    text-align: right;
    background-color: #354759;
    color: white;
    align-self: flex-end;
    border-radius: 15px;
    margin-left: 10px;
  }
  
  .bot .message {
    text-align: left;
    background-color: #fff;
    align-self: flex-start;
    border-radius: 15px;
  }
  

  .input-container {
    display: flex;
    padding: 10px;
    background-color: #f1f0f0; 
    border-top: 4px solid #fff;
  }
  
  .input-container input {
    flex: 1;
    padding: 13px;
    border: 1px solid #ccc;
    border-radius: 15px;
  }

  .input-container button{
    color: #674f87;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 2px;
  }

  .input-container button:hover{
    color: #ef798a;
  }

  .btnUpload{
    padding: 6px 8px;
    color: #674f87;
    border: none;
    border-radius: 50%;
  }
  .btnUpload:hover{
    color: #ef798a;
  }
  .chat-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 15px;
    background: none;
    border: none;
    color: white;
    font-size: 25px;
    cursor: pointer;
    
  }
  
  /* Rest of the CSS remains the same */
  
  .chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: none;
    display: flex;
    cursor: pointer;
    
  }
  
  .chat-icon img {
    width: 70px;
    height: 70px;
  }

  /* Hide the Scrollbar */
.messages::-webkit-scrollbar {
    display: none;
  }
  
  /* Optional: Define Styles for Scrollbar Track and Thumb */
  .messages::-webkit-scrollbar-track {
    background-color: transparent; /* Change as needed */
  }
  
  .messages::-webkit-scrollbar-thumb {
    background-color: transparent; /* Change as needed */
  }
  /* Define media query for smaller screens */


  /* Define media query for smaller screens */
@media only screen and (max-width: 600px) {
    .chat-container {
      max-width: 100%; /* Allow full width on smaller screens */
    }
  
    .messages {
      max-height: 50vh; /* Reduce maximum height for smaller screens */
    }
  
    .chat-icon {
      bottom: 10px; /* Adjust chat icon position */
      right: 10px;
      width: 50px; /* Reduce chat icon size */
      height: 50px;
    }
  
    .chat-icon img {
      width: 60px; /* Reduce chat icon image size */
      height: 60px;
    }
  }
  